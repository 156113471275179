<template>
  <Teleport to="body">
    <Transition
      name="modal">
      <div
        v-show="isOpen"
        class="ModalBase">

        <header
          class="__header">
          <button
            class="__x"
            type="button"
            @click="closeModal">
            <IconBase
              name="x"
              width="14"
              height="14"
              color="#FFFFFF">
            </IconBase>
          </button>
        </header>

        <div
          class="__wrapper"
          @click.self="closeModal">

          <div
            class="__container"
            :style="computedContainerStyle">
            <slot name="default" :close="closeModal" />
          </div>

        </div>
      </div>
    </Transition>
  </Teleport>
</template>


<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import useKey from '@/use/key';
import IconBase from '@/components/IconBase.vue';

// Props
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [Number, String],
    default: '95%',
  },
  height: {
    type: [Number, String],
    default: 'auto',
  },
  maxWidth: {
    type: [Number, String],
    default: 600,
  },
  maxHeight: {
    type: [Number, String],
    default: 600,
  },
});


// Emits
const emit = defineEmits([
  'close',
]);


// Methods
const closeModal = () => emit('close');


// Composables
useKey(['Escape'], closeModal);


// Computeds
const computedContainerStyle = computed(() => ({
  width: typeof props.width === 'number' ? `${props.width}px` : props.width,
  height: typeof props.height === 'number' ? `${props.height}px` : props.height,
  maxWidth: typeof props.maxWidth === 'number' ? `${props.maxWidth}px` : props.maxWidth,
  maxHeight: typeof props.maxHeight === 'number' ? `${props.maxHeight}px` : props.maxHeight,
}));
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.ModalBase {
  display: table;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.5);
  transition: opacity 0.3s ease;
}


// Header
.__header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  justify-content: flex-end;
  pointer-events: none;
}
.__x {
  width: 40px;
  height: 40px;
  background-color: rgba($black, 0.3);
  border-radius: 50%;
  pointer-events: auto;
}


// Body
.__wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.__container {
  width: 95%;
  margin: 0 auto;
  background-color: rgba($white, 0.2);
  border-radius: $radius;
  box-shadow: 0 8px 24px -8px rgba($black, 0.5);
  overflow: hidden;
  transition: all 0.3s ease;
}


// Transitions
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  .__container {
    transform: scale(1.05);
  }
}
</style>
