/*
 * References:
 * https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview?tab=advanced
 */
import { camelCase } from 'lodash';
import useUtils from '@/use/utils';
import useCountry from '@/use/country';

const { loadScript } = useUtils();
const { countryRegionByCode } = useCountry();

const CALENDLY_REGION_URLS = [
  {
    region: null,
    url: 'https://calendly.com/domo-onboarding/free-trial-walk-through?utm_content=website_signup',
  },
  {
    region: 'EMEA',
    url: 'https://calendly.com/domoemea/free-trial-walk-through?utm_content=website_signup',
  },
  {
    region: 'APAC',
    url: 'https://calendly.com/domo-apac/free-trial-walk-through?utm_content=website_signup',
  },
];


// Load Calendly script
async function loadCalendlyScript() {
  if (window.Calendly) return;

  try {
    await loadScript({
      src: 'https://assets.calendly.com/assets/external/widget.js',
    });
  } catch (error) {
    console.error('FAILED TO LOAD CALENDLY: ', error);
  }
}


// Utils
function isWindowPostMessageCalendlyEvent(e) {
  return e.origin === 'https://calendly.com'
    && e.data.event
    && e.data.event.indexOf('calendly.') === 0;
}

function handleCalendlyMessage(hooks, e) {
  if (!isWindowPostMessageCalendlyEvent(e)) return;

  const { event, payload } = e.data;

  const providedHook = hooks[camelCase(event.replace('calendly.', ''))];

  if (!providedHook || typeof providedHook !== 'function') return;

  providedHook(payload);
}

function getCalendlyUrlByCountry(countryCode) {
  const isJapan = countryCode === 'JP';

  if (isJapan) return 'https://calendly.com/yoshimasa-yamamoto/domo-japan';

  const countryRegion = countryRegionByCode(countryCode);
  const calendlyRegion = CALENDLY_REGION_URLS.find((cr) => cr?.region === countryRegion);

  return calendlyRegion ? calendlyRegion.url : CALENDLY_REGION_URLS[0].url;
}



// Init Calendly
/*
 * "hooks" is object with properties mapped after calendly event names:
 * 'calendly.profile_page_viewed' -> profilePageViewed
 * 'calendly.event_type_viewed' -> eventTypeViewed
 * 'calendly.date_and_time_selected' -> dateAndTimeSelected
 * 'calendly.event_scheduled' -> eventScheduled
 *
 * "prefill" takes "customAnswers" property with keys "a1, a2, etc." -see docs
 */
async function initCalendly({
  // url,
  country,
  el,
  prefill,
  hooks,
}) {
  await loadCalendlyScript();

  const url = getCalendlyUrlByCountry(country);

  window.Calendly.initInlineWidget({
    url, // <- ?hide_event_type_details=1&hide_landing_page_details=1
    prefill,
    parentElement: el,
    // utm: {},
  });

  if (!hooks) return;

  window.addEventListener('message', handleCalendlyMessage.bind(null, hooks));
}


// Exports
export default function useCalendly() {
  return {
    initCalendly,
  };
}
