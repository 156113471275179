import useEventListener from '@/use/event-listener';

export default function useKey(keys = [], callback = null) {
  useEventListener(window, 'keyup', (e) => {
    const isKey = keys.includes(e.key);
    const isCallback = callback && typeof callback === 'function';

    if (!isKey || !isCallback) return;

    callback(e.key);
  });
}
